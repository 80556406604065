.mainContainer {
    padding: 20px 40px !important;
}

.title {
    text-transform: none !important;
}

.badge {
    line-height: 2;
    text-transform: uppercase;
    gap: 8px !important;
    flex: none !important;
    order: 1 !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 2px 8px !important;
    flex-grow: 0 !important;
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 25px !important;
    flex-direction: row !important;
}

.modalHeader {
    background-color: #dc3545;
}

.modalIcon {
    margin-bottom: 1rem;
}

.returned {
    background-color: rgba(52, 58, 64, 0.15) !important;
    color: #000 !important;
}

.succeeded {
    background: #deece6 !important;
    color: #408458 !important;
}

.approved,
.settled {
    background-color: #deece6 !important;
    color: #408458 !important;
}

.open {
    background-color: #d4daeb !important;
    color: #2850c3 !important;
}
.captured,
.pending {
    background-color: #f9d6aa !important;
    color: #000 !important;
}

.lost,
.won {
    background-color: #dee2e6 !important;
    color: #212529 !important;
}
.failed {
    background-color: #e6b8b3 !important;
    color: #bb1144 !important;
}
.accepted {
    background-color: #add8e6 !important;
    color: #00008b !important;
}
.request {
    background-color: #ffffed !important;
    color: #8b8000 !important;
}

.rotateDown {
    transform: rotate(-90deg);
}

.rotateUp {
    transform: rotate(90deg);
}

.alignTextRight {
    text-align: right;
}

.greyButton {
    color: #6c757d !important;
    background-color: white !important;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    float: right;
    border-color: #6c757d !important;
}

.greyButton:hover {
    color: white !important;
    background-color: #6c757d !important;
}

.greyButton:hover:disabled {
    color: #6c757d !important;
    background-color: white !important;
    cursor: crosshair;
}

.csvLink {
    display: none;
}

.filterApplyButton {
    display: flex;
    align-items: flex-end;
}

.filterApplyButton:focus-visible {
    box-shadow: none !important;
    background-color: #0d6efd;
}

.filterRow {
    background-color: #f8f9fa;
    padding: 6px 12px;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 12px;
}

.greyLink {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    gap: 10px;
    width: auto;
    min-width: 65px;
    height: 37px;
    background: #ffffff;
    border: 1px solid #6c757d;
    border-radius: 6px;
    flex: none;
    order: 0;
    flex-grow: 0;

    text-decoration: none;
    color: #6c757d !important;
    background-color: white !important;
    font-weight: 700;
    font-size: 14px;
    line-height: 25px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    float: right;
}

.greyLink:hover {
    color: white !important;
    background-color: #6c757d !important;
    text-decoration: none;
}

.greyLink:hover:disabled {
    color: #6c757d !important;
    background-color: white !important;
    cursor: crosshair;
    text-decoration: none;
}

.tabs:has(li) {
    --st-nav-tabs-border-width: 4px;
    --st-nav-tabs-link-active-color: #0d6efd;
    --st-nav-tabs-link-active-border-color: transparent transparent #0d6efd;
    --st-nav-tabs-link-hover-border-color: transparent transparent lightgrey;
    --st-nav-link-color: #6c757d;
    --st-nav-link-hover-color: #6c757d;
    --st-nav-link-padding-x: 0.5rem;
    border-bottom: 4px solid lightgrey;
    font-weight: bold;
    margin-bottom: 20px;
}

.payInput {
    padding: 10px;
    width: 100%;
    height: 38px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.payInput:focus-visible {
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 1px #2684ff;
    box-sizing: border-box;
    border-color: #2684ff;
    outline-color: #2684ff;
}

.errorText {
    color: red;
    font-size: small;
}

.statusActive {
    border: 1px solid;
}

.modalButtons {
    width: 100%;
    gap: 2rem;
}

.modalWarningBody {
    align-items: center;
    justify-content: center;
}

.modalBody {
    padding: 1.5rem 3rem;
    margin: auto;
}

.modalHeadText {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}

.modalContent {
    text-align: center;
}

.EditNotificationModalButton {
    justify-content: center;
    align-items: center;
}

.contentModal {
    width: 85%;
}

.parentModal {
    align-items: center;
    justify-content: center;
}

.changeLocationModalAlert {
    padding: 0 20px 0 20px;
}

.changeLocationModalLabel {
    display: block;
}
.respondButton {
    float: left;
}

.respondButton {
    float: left;
}
.noDisplay {
    display: none;
}

.transactionExpandedRowColumns {
    padding-top: 10px;
    padding-right: 0px;
}
.collapseContentHidden {
    overflow: hidden;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    max-height: 0;
    opacity: 0;
}

.collapseContentVisible {
    max-height: 1000px;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    opacity: 1;
}
.container {
    margin-top: 20px;
}

.header {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 14px;
    overflow-x: auto;
}

.title {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}

.terminalHeaderBadge {
    color: blue;
    font-weight: bold;
    font-size: 14px;
    background-color: lightblue;
    padding: 2px 4px;
    border-radius: 20px;
    margin-left: 8px;
}

.arrow {
    display: inline-block;
    color: grey;
    padding: 2px;
    border-radius: 4px;
    transition: transform 0.2s;
}

.arrow svg {
    width: 16px;
    height: 16px;
}

.terminalHeaderPointer {
    color: grey;
}

.refundInput {
    width: 80%;
    margin-top: 1rem;
}

.refundMessage {
    margin-top: 20px;
}

.transactionModalButton {
    justify-content: center;
    align-items: center;
}

.refundInputContainer {
    margin-bottom: 3rem;
}

.RadioInputText {
    margin-left: 10px;
}
.TransactionAmountText {
    font-size: 1.5rem;
}
.PayMethodText {
    color: gray;
}

.RadioContainer {
    margin-left: 1rem;
    margin-bottom: 2rem;
}
.refundExpandedRowColumns {
    padding-top: 60px;
}

.refundExpandedRowColumnswithDate {
    padding-top: 40px;
}

.voidModalContainer {
    width: 100%;
    margin-bottom: 0.5rem;
}

.amountAvailableContainer {
    margin-bottom: 1rem;
}
