.mainNav {
    height: 59px;
    background: linear-gradient(58.07deg, #426a99 0%, #35a3ab 100%);
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 14px;
    gap: 10px;
    width: 107px;
    height: 38px;
    background: #ffffff;
    border-radius: 33px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.navLink:hover {
    color: #fff;
}
