.adminNav {
    background-color: gray;
}

.adminNavLink {
    color: white;
}

.adminBadge {
    border-radius: 15%;
    font-size: 1.5em;
    background-color: orange;
}
